<template>
  <div>
    <v-container>
      <h1 class="pb-6">My Crops</h1>
      <v-row>
        <v-col v-for="(menu, idx) in menus" :key="idx" cols="6" md="3" sm="3">
          <v-card ripple link :to="'/' + menu.url" rounded="lg" class="pa-6">
            <v-img class="ma-6" :src="getImage(menu.img)"></v-img>
            <p class="text-center">{{ menu.title }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menus: [
        {
          img: "soil_preparation.svg",
          title: "Soil Preparation",
        },
        {
          img: "planting.svg",
          title: "Planting",
          url: "planting",
        },
        {
          img: "weed_management.svg",
          title: "Weed Management",
        },
        {
          img: "irrigation.svg",
          title: "Irrigation",
        },
        {
          img: "harvest.svg",
          title: "Harvest",
        },
        {
          img: "storage.svg",
          title: "Storage",
        },
        {
          img: "ship_and_track.svg",
          title: "Ship and Track",
        },
        {
          img: "observation.svg",
          title: "Observation",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return "/img/crops/" + img;
    },
  },
};
</script>
